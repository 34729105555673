import { Controller } from "stimulus"

export default class extends Controller {
    static targets = [ "total", "subtotal", "errorMessage", "yesNoInput", "yesNoInputErrorMessage" ];

    connect() {
        // this.clearForm()
        this.calculate()
    }

    calculate() {
        this.validateSubtotals()
        let totalFunds = this.calculateTotal()
        let totalFundsFormatted = formatter.format(totalFunds)
        this.totalTarget.textContent = `Total: $${totalFundsFormatted}`
    }

    calculateIgnoreRemoved(e) {
        // Set timeout to allow for the element to be removed
        setTimeout(() => this.calculate(), 500)
    }

    clearForm() {
        this.subtotalTargets.forEach((subtotalTarget) => {
            subtotalTarget.value = 0
        })
    }

    validateSubmit(e) {
        let isSubtotalValid = this.validateSubtotals()
        let isYesNoValid = this.validateYesNoInputs()

        if (isSubtotalValid && isYesNoValid) {
            this.errorMessageTarget.textContent = ""
        } else {
            e.preventDefault()
        }
    }

    calculateTotal() {
        let totalFunds = 0

        this.subtotalTargets.forEach((subtotalTarget) => {
            let subtotalNumeric = subtotalTarget.value
            totalFunds = totalFunds + (parseFloat(subtotalNumeric) || 0)
        })
        return totalFunds
    }

    validateYesNoInputs() {
        const isValid = this.yesNoInputTargets.every((yesNoInput) => {
            let fieldValid = true;
            let errorMessageDiv = this.yesNoInputErrorMessageTargets.find(tag => tag.getAttribute('name') === yesNoInput.getAttribute('name'))

            if (!yesNoInput.value) {
                fieldValid = false
                errorMessageDiv.textContent = "Please select 'Yes' or 'No'."
            } else {
                errorMessageDiv.textContent = null
            }

            return fieldValid
        }) || this.yesNoInputTargets.length === 0

        return isValid
    }

    validateSubtotals() {
        let isValid = this.subtotalTargets.length > 0
        this.subtotalTargets.forEach((subtotalTarget) => {
            if (subtotalTarget.value > 0) {
                subtotalTarget.classList.remove('is-invalid')
                this.errorMessageTarget.classList.add('hidden')
                this.errorMessageTarget.textContent = null

            } else {
                subtotalTarget.classList.add('is-invalid')
                this.errorMessageTarget.classList.remove('hidden')
                isValid = false
                this.errorMessageTarget.textContent = "Please enter a valid Budget Amount for each funding source."
            }
        })
        return isValid
    }
}
let formatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2
  });
