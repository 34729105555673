export function initializeModal() {
    let modals = document.querySelectorAll('.modal-open')

    if (modals.length > 0) {
        for (let i = 0; i < modals.length; i++) {
            modals[i].addEventListener('click', function (event) {
                event.preventDefault()
                toggleModal()
            })
        }

        const modal = document.querySelector('#modal');

        let open = ["/members_hub/dashboard", "/members_hub/funding_plans"].includes(window.location.pathname) ? modal.dataset.open : false

        if (open) {
            toggleModal()
            modal.setAttribute("open", false);
        }

        let closemodal = document.querySelectorAll('.modal-close')

        for (let i = 0; i < closemodal.length; i++) {
            closemodal[i].addEventListener('click', function (event) {
                toggleModal()
            })
        }

        document.onkeydown = function (evt) {
            evt = evt || window.event
            let isEscape = false
            if ("key" in evt) {
                isEscape = (evt.key === "Escape" || evt.key === "Esc")
            } else {
                isEscape = (evt.keyCode === 27)
            }
            if (isEscape && document.body.classList.contains('modal-active')) {
                toggleModal()
            }
        };


    }
}

/**
 * Toggles the visibility of a modal by adding or removing CSS classes to the modal and the body element.
 *
 */
function toggleModal() {
    const body = document.querySelector('body')
    const modal = document.querySelector('.modal')
    modal.classList.toggle('opacity-0')
    modal.classList.toggle('pointer-events-none')
    body.classList.toggle('modal-active')
}