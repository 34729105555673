import {getMetaValue} from "./index";

export const getRequest = async (url) => {
    const response = await fetch(url, {
        method: "GET",
        credentials: "include",
        headers: {
            'Content-Type': 'application/json',
            "X-CSRF-Token": getMetaValue("csrf-token"),
        },
    });
    return response.json();
}

export const postRequest = async (url, data) => {

        const response = await fetch(url, {
            method: "POST",
            credentials: "include",
            headers: {
                'Content-Type': 'application/json',
                "X-CSRF-Token": getMetaValue("csrf-token"),
            },
            body: JSON.stringify(data)
        });
        return response;
}