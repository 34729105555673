import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "output", "add" ]

  append(event) {
    let filename =  event.target.files[0].name
    let filetype =  event.target.files[0].type

    let pattern = /[^\/]+$/
    let extension = pattern.exec(filetype)[0]
    let shortFilename = filename.replace(`.${extension}`, "").substring(0,20).concat(`.${extension}`)
    this.outputTarget.textContent = `${shortFilename} (Click "Save" to upload this document)`
    this.addTarget.classList.remove("hidden")
  }
}