import {PROVIDER_FOCUS_BUTTON_CLASS} from "./interactions";

const SELECT_PROVIDER_CONTAINER = "selected-provider-container";

const renderProviderDetails = (provider) => {
    const container = document.getElementById(SELECT_PROVIDER_CONTAINER);
    if (!container || !provider) return; // Don't render anything
    container.innerHTML = providerDetailsTemplate(provider);
}

const providerDetailsTemplate = (provider) => {
    return `
<div class="selected-provider shadow-md shadow-slate-400 rounded-lg overflow-hidden">

    <div class="px-4 pt-4 flex justify-between flex-row items-start">
        ${provider.avatar ? `<img src="${provider.avatar}" alt="${provider.first_name}" class="h-14 w-14 rounded-full" />` : `<svg class="h-14 w-14 rounded-full  opacity-50" fill="currentColor" viewBox="0 0 24 24"><path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" /></svg>`}
        <div>
            <span class="me-2 text-xs ${provider.work_status_icon.htmlClass}">
                ${provider.work_status_icon.text}
            </span>
            <span class="text-xs ${provider.type_of_provider.htmlClass}">
                ${provider.type_of_provider.text}
            </span>
        </div>
        
    </div>

    <div class="p-4">
        <div class="flex items-center justify-start">
            <button data-provider-id="${provider.id}" class="${PROVIDER_FOCUS_BUTTON_CLASS.substring(1)} text-black opacity-50 hover:text-ascendPurple-dark transition p-1 -ms-1 hover:opacity-100">
                <i class="fas fa-location-crosshairs cursor-pointer pointer-events-none"></i>    
            </button>
            <a href="/providers/${provider.id}">
                <h2 class="text-xl font-semibold hover:underline cursor-pointer">${provider.first_name} ${provider.last_name}</h2>
            </a>
        </div>
        <p class="text-gray-700">${provider.full_address}</p>
    </div>
</div>
`;
}

export default renderProviderDetails;