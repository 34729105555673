import {Controller} from "stimulus";
import {getMetaValue} from "../helpers";
import tippy from 'tippy.js';

const renderMethods = {
    avatar: 'renderAvatar',
    bubble_text: 'renderBubbleText',
    tags: 'renderTags',
    action_link: 'renderActionButton',
    onboarding_progress: 'renderTimeline'
};


export default class extends Controller {

    static targets = ["template", "list", "loader", "no_content", "state_filter"];


    connect() {
        this.url = this.element.dataset.dashboardTableRowUrlValue;
        this.fetchData();

        if (this.hasState_filterTarget) {
            this.state_filterTarget.addEventListener('change', () => this.fetchData());
        }
    }

    /**
     * Fetches data based on the current filter state and updates the DOM accordingly.
     */
    async fetchData() {
        this.toggleVisibility({ show: this.loaderTarget, hide: [this.no_contentTarget, this.listTarget] });

        const params = this.getParams();
        try {
            const response = await fetch(`${this.url}?${params}`, {
                method: 'GET',
                credentials: 'include',
                headers: {
                    "X-CSRF-Token": getMetaValue("csrf-token"),
                    "Content-Type": "application/json"
                },
            });
            const data = await response.json();

            if (data.length > 0) {
                this.listTarget.innerHTML = "";
                data.forEach(item => this.appendToList(item));
                this.toggleVisibility({ show: this.listTarget, hide: [this.no_contentTarget, this.loaderTarget] });
            } else {
                this.toggleVisibility({ show: this.no_contentTarget, hide: [this.listTarget, this.loaderTarget] });
            }
        } catch (error) {
            console.error('Error:', error);
        }
    }

    /**
     * Constructs a query string with parameters based on the current state of filters.
     * @returns {string} A query string with encoded parameters.
     */
    getParams() {
        let params = "";
        const stateFilter = this.hasState_filterTarget ? this.state_filterTarget.value : null;
        if (stateFilter) {
            params = `location=${encodeURIComponent(stateFilter)}`;
        }
        return params;
    }

    /**
     * Appends a new item to the list based on the template and item data.
     * This method clones the template for a new list item, sets its content based on the item data,
     * and then appends it to the list. It dynamically calls rendering methods for specific item properties
     * like avatar, bubble text, tags, action link, and onboarding progress if they exist in the item object.
     *
     * @param {Object} item - The data object for the item to append. This object may contain properties
     *                        such as 'link', 'title', 'subtitle', 'right_text', 'avatar', 'bubbleText',
     *                        'tags', 'action_link', and 'onboarding_progress' which are used to populate
     *                        the content of the cloned template before appending it to the list.
     */
    appendToList(item) {
        const clone = this.templateTarget.content.firstElementChild.cloneNode(true);
        clone.querySelector('[data-template-link]').href = item.link;
        clone.querySelector('[data-template-title]').innerHTML = `${item.title} <span class="text-gray-400 text-sm font-normal">${item.subtitle}</span>`;
        clone.querySelector('[data-template-right-text]').textContent = item.right_text;

        ['avatar', 'bubble_text', 'tags', 'action_link', 'onboarding_progress'].forEach(renderMethod => {
            if (item[renderMethod]) {
                this[renderMethods[renderMethod]](clone, item);
            }
        });

        this.listTarget.appendChild(clone);
    }

    /**
     * Renders an avatar image within a given template clone based on the item's avatar URL.
     * This method selects the avatar icon element within the template clone and sets its source
     * attribute to the URL provided in the item's avatar property. If the avatar icon element exists,
     * the avatar image is updated; otherwise, no action is taken.
     *
     * @param {HTMLElement} templateClone - The clone of the template where the avatar will be rendered.
     * @param {Object} item - The data object containing the avatar URL.
     */
    renderAvatar(templateClone, item) {
        const avatarIcon = templateClone.querySelector('[data-template-avatar-icon]');
        if (avatarIcon) avatarIcon.src = item.avatar
    }

    /**
     * Renders bubble text within a given template clone based on the item's bubble text details.
     * This method dynamically updates the bubble text element by making it visible and setting its content
     * to the provided bubble text from the item. Additionally, it applies any custom classes specified in the
     * item's bubble_class property to the bubble text element, allowing for flexible styling.
     *
     * @param {HTMLElement} templateClone - The clone of the template where the bubble text will be rendered.
     * @param {Object} item - The data object containing the bubble text and optional bubble_class for styling.
     */
    renderBubbleText(templateClone, item) {
        const bubble = templateClone.querySelector('[data-template-bubble-text]');
        bubble.classList.remove("hidden");
        bubble.innerHTML = item.bubble_text;

        // Break down the classes and add them
        bubble.classList.add(...(item.bubble_class ?? "").split(" "));
    }

    /**
     * Renders tag elements within a given template clone based on the item's tag details.
     * This method iterates over each tag in the item's tags array, dynamically creating and appending
     * a span element for each tag to the middle text container. Each tag is styled with background and text color
     * classes based on the tag's color property. The method ensures that the middle text container is visible
     * by removing the 'hidden' class.
     *
     * @param {HTMLElement} templateClone - The clone of the template where the tags will be rendered.
     * @param {Object} item - The data object containing the tags array, each tag with a color and text property.
     */
    renderTags(templateClone, item) {
        const middleText = templateClone.querySelector('[data-template-middle-text]');
        middleText.classList.remove("hidden");

        item.tags.forEach(tag => {
            middleText.innerHTML = middleText.innerHTML + `<span class="py-1 px-2 rounded-xl text-xs bg-${tag.color}-100 text-${tag.color}-800 mr-1">${tag.text}</span>`;
        })
    }

    /**
     * Renders an action button within a given template clone based on the item's action link details.
     * This method dynamically creates an action button and makes it visible by removing the 'hidden' class.
     * The button is styled with predefined classes and includes the action link's URL, method, and text.
     *
     * @param {HTMLElement} templateClone - The clone of the template where the action button will be rendered.
     * @param {Object} item - The data object containing the action link details.
     */
    renderActionButton(templateClone, item) {
        const actionButton = templateClone.querySelector('[data-template-action-button]');
        actionButton.classList.remove("hidden");
        actionButton.innerHTML = `<a class="btn purple-btn text-sm" href=${item.action_link['url']} data-method=${item.action_link['method']}>
            ${item.action_link['text']}
    </a>`
    }


    /**
     * Renders the timeline for onboarding progress within a given template clone.
     * This method dynamically calculates the width for each timeline item based on the parent container's width
     * and the number of items in the onboarding progress. It then creates and appends each timeline item and,
     * if not the last item, a connecting line to the timeline container.
     *
     * @param {HTMLElement} templateClone - The clone of the template where the timeline will be rendered.
     * @param {Object} item - The data object containing the onboarding progress information.
     */
    renderTimeline(templateClone, item) {
        const timelineContainer = templateClone.querySelector('[data-template-timeline]');

        // Get the parent container width
        const parentWidth = this.listTarget.parentElement.offsetWidth;

        // Calculate the width that each timeline item and then the line shoudl be
        const itemWidth = (parentWidth / item.onboarding_progress.length) - 10;
        timelineContainer.style.setProperty('--item-width', `${itemWidth}px`);

        // For each element in the onboarding progress, create a timeline item
        item.onboarding_progress.forEach((timelineItem, index) => {
            const isLast = index === item.onboarding_progress.length - 1;
            timelineContainer.appendChild(this.createTimelineItem(timelineItem, itemWidth));
            if (!isLast) {
                timelineContainer.appendChild(this.createTimeLineLine(itemWidth));
            }
        })
    }

    /**
     * Create a timeline line for the onboarding progress
     * @param itemWidth - The width of the line
     * @returns {HTMLDivElement} - The created line
     */
    createTimeLineLine(itemWidth) {
        const timelineLine = document.createElement('div');
        timelineLine.className = 'w-full left-full h-0.5 bg-gray-400 mt-4';
        timelineLine.style.width = `${itemWidth}px`;
        return timelineLine;
    }

    /**
     * Create a timeline item for the onboarding progress
     * @param item - The item to be displayed
     * @param itemWidth - The width of the item
     * @returns {HTMLDivElement}
     */
    createTimelineItem(item, itemWidth) {
        const timelineItem = document.createElement('div');
        timelineItem.className = 'flex flex-col items-center relative';
        timelineItem.style.width = `${itemWidth}px`;

        // Timeline circle
        const timelineCircle = document.createElement('div');
        timelineCircle.className = 'w-8 h-8 rounded-full bg-white border-2 border-gray-400 flex items-center justify-center';

        const icon = document.createElement('i');
        icon.className = item.date ? (item.subtitle.includes("booked") ? 'fas fa-minus text-orange-500' : 'fas fa-check text-green-500') : 'fas fa-times text-red-500';
        timelineCircle.appendChild(icon);

        const timelineLabel = document.createElement('span');
        timelineLabel.className = 'text-xs mt-2 text-center';
        timelineLabel.textContent = item.title;

        const timelineText = document.createElement('div');
        timelineText.className = 'py-2 text-center';
        timelineText.innerHTML = `<strong class="text-sm">${item.title}</strong>${item.subtitle ? `<br><small class="text-xs">${item.subtitle}</small>` : ''}<br/><span class="text-xs">${item.date ? new Date(item.date).toLocaleDateString() : 'Pending'}</span>`;

        // Tooltip content
        tippy(timelineCircle, {
            content: timelineText,
        });

        timelineItem.appendChild(timelineCircle);
        timelineItem.appendChild(timelineLabel);

        return timelineItem;
    }

    /**
     * Toggles the visibility of specified elements.
     * @param {Object} options - The elements to show and hide.
     */
    toggleVisibility({ show, hide }) {
        if (show) show.classList.remove("hidden");
        if (hide) hide.forEach(element => element.classList.add("hidden"));
    }
}