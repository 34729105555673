import {MEMBER_FOCUS_BUTTON_CLASS} from "./interactions";

/**
 * Renders a list of members to the DOM.
 * This function takes an array of member objects, iterates over them,
 * and appends each member's information as HTML to the member list container.
 *
 * @param {Array} members - An array of member objects to be rendered.
 */
export const renderMemberList = (members) => {
    const memberListContainer = document.getElementById("member-list");

    memberListContainer.innerHTML = "";
    members.forEach(member => {
        memberListContainer.innerHTML += memberListItem(member);
    });
}

/**
 * Generates the HTML string for a single member item.
 * This function constructs the HTML representation of a member's information,
 * including their avatar, name, and address. It returns a string of HTML
 * that can be injected into the DOM. If the member object is not provided,
 * the function returns without producing any output.
 *
 * @param {Object} member - The member object containing information to be displayed.
 * @returns {string|undefined} The HTML string representing the member's information or undefined if no member is provided.
 */
const memberListItem = (member) => {
    if (!member) return;

    return `<div>
        <div class="flex justify-between items-center p-4 border-b border-gray-200">
            <div class="flex items">
                 ${member.avatar ? `<img src="${member.avatar}" alt="${member.first_name}" class="h-14 w-14 rounded-full" />` : `<svg class="h-14 w-14 rounded-full opacity-50" fill="currentColor" viewBox="0 0 24 24"><path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" /></svg>`}
                <div class="ml-4">
                    <div class="flex flex-row items-center justify-start">
                         <button data-member-id="${member.id}" class="${MEMBER_FOCUS_BUTTON_CLASS.substring(1)} text-black opacity-50 hover:text-ascendPurple-dark transition p-1 -ms-1 hover:opacity-100">
                           <i class="fas fa-location-crosshairs cursor-pointer pointer-events-none"></i>
                         </button>
                        <h2 class="text-lg font-semibold">${member.first_name} ${member.last_name}</h2>
                    </div>
                    <p class="text-gray-700">${member.full_address ?? "No address entered"}</p>
                </div>
            </div>
            <button data-member-id="${member.id}" class="member-map-select-button cursor-pointer bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-md group transition" title="Select ${member.first_name}">
                <i class="pointer-events-none fas fa-long-arrow-right group-hover:translate-x-0.5 group-hover:scale-x-110 transition"></i>
            </button>
</div>`


}