import renderProviderDetails from "./render_provider_details";
import {renderMemberDetails} from "./render_member_details";
import {MarkerClusterer} from "@googlemaps/markerclusterer";
import {DEFAULT_ZOOM_LEVEL} from "./utilities";
import {mapManager} from "./map_manager";

import selectionManager from "./selection_manager";
import {
    getCurrentMemberFilter, getCurrentProviderFilter,
    hideLoadingBar,
    hideMemberSearch,
    hideProviderSearch,
    showLoadingBar,
    showMemberDetails,
    showProviderDetails
} from "./interactions";
import {renderMemberList} from "./render_member_list";
import {renderProvidersList} from "./render_provider_list";
import {enableDirectionsButton} from "./directions_management";


let AdvancedMarkerElement;
let InfoWindow;

const ICON_SIZE = 35;
const MEMBER_BADGE = "M";

/**
 * Cache for markers.
 * The cache stores member and provider markers, as well as the bounds of the map.
 * @type {{members: {}, bounds: null, providers: {}}}
 */
export const markerCache = {
    members: {},
    providers: {},
    bounds: null
};

let clusterer;
let markers = [];

/**
 * Initializes the markers on the map.
 * This function should be called once when the map is first initialized.
 * @returns {Promise<void>} A promise that resolves when the markers are initialized.
 */
export const initializeMarkers = async () => {

    AdvancedMarkerElement = await google.maps.importLibrary("marker").AdvancedMarkerElement;
    InfoWindow = await google.maps.importLibrary("maps").InfoWindow;

    clusterer = new MarkerClusterer({
        markers,
        map: mapManager.getMap(),
        algorithmOptions: {maxZoom: DEFAULT_ZOOM_LEVEL}
    });
    mapManager.getMap().addListener('click', hideAllInfoWindows);
}


/**
 * Creates a marker with the given position, icon, and title.
 * @param {Object} position - The position of the marker.
 * @param {HTMLElement} icon - The marker icon.
 * @param {string} title - The title of the marker.
 * @returns {Object} - The created marker.
 */
const createMarker = async (position, icon, title) => {
    const {AdvancedMarkerElement} = await google.maps.importLibrary("marker");
    const {InfoWindow} = await google.maps.importLibrary("maps");

    const marker = new AdvancedMarkerElement({
        position,
        map: mapManager.getMap(),
        title,
        content: icon,
        gmpClickable: true
    });

    marker.infoWindow = new InfoWindow();
    markers.push(marker);
    clusterer.clearMarkers();
    clusterer.addMarkers(markers);

    return marker;
};


const fetchMemberMarkers = async () => {
    const bounds = mapManager.getMap().getBounds();
    const boundsString = bounds.toString();

    const ne = bounds.getNorthEast();
    const sw = bounds.getSouthWest();
    const params = `north=${ne.lat()}&south=${sw.lat()}&east=${ne.lng()}&west=${sw.lng()}`;
    const response = await fetch(`api/v2/matchmaking/members_within_bounds?${params}`);
    const data = await response.json();

    // Update cache only with new markers
    data.forEach(member => {
        if (!markerCache.members[member.id]) {
            markerCache.members[member.id] = member; // Assuming member object includes all necessary marker data
        }
    });

    const selectedMember = selectionManager.getSelectedMember();
    if (!selectedMember) renderMemberList(data); // Show members in the list

    // Update the bounds in the cache
    markerCache.bounds = boundsString;

    return Object.values(markerCache.members);
};

const fetchProviderMarkers = async () => {
    const bounds = mapManager.getMap().getBounds();
    const boundsString = bounds.toString();

    // Use the same bounds check to avoid unnecessary fetches
    const ne = bounds.getNorthEast();
    const sw = bounds.getSouthWest();
    const params = `north=${ne.lat()}&south=${sw.lat()}&east=${ne.lng()}&west=${sw.lng()}`;
    const response = await fetch(`api/v2/matchmaking/providers_within_bounds?${params}`);
    const data = await response.json();


    const selectedProvider = selectionManager.getSelectedProvider();
    if (!selectedProvider) renderProvidersList(data); // Show members in the list

    // Update cache with new provider markers, avoiding duplicates
    data.forEach(provider => {
        if (!markerCache.providers[provider.id]) {
            markerCache.providers[provider.id] = provider; // Assuming provider object includes all necessary marker data
        }
    });

    // Update the bounds in the cache
    markerCache.bounds = boundsString;

    return Object.values(markerCache.providers);
}


/**
 * Fetches and updates markers on the map.
 */
export const fetchAndUpdateMarkers = async () => {
    showLoadingBar();

    const [membersMarkerData, providersMarkerData] = await Promise.all([
        fetchMemberMarkers(mapManager),
        fetchProviderMarkers(mapManager)
    ]);

    hideLoadingBar();

    // We want to aggregate the members but keep the providers separate
    const aggregatedData = aggregateMarkersByLocation(membersMarkerData);
    const updatedMarkerIds = new Set();

    updateMemberMarkers(aggregatedData, updatedMarkerIds);
    updateProviderMarkers(providersMarkerData, updatedMarkerIds);
};

/**
 * Hides all info windows.
 */
const hideAllInfoWindows = () => {
    markers.forEach(marker => {
        if (marker.infoWindow) marker.infoWindow.close();
    });
};


/**
 * Updates member markers on the map.
 * @param {Map} aggregatedData - Aggregated member marker data by location.
 * @param {Set} updatedMarkerIds - Set of updated marker IDs.
 */
const updateMemberMarkers = (aggregatedData, updatedMarkerIds) => {
    aggregatedData.forEach(async (members, locationKey) => {
        const [latitude, longitude] = locationKey.split(',').map(Number);
        const position = {lat: latitude, lng: longitude};
        const icon = createMarkerIcon(members[0].avatar, MEMBER_BADGE);

        if (!markerCache[locationKey]) {
            markerCache[locationKey] = await addMemberMarker(members, position, icon);
        }
        updatedMarkerIds.add(locationKey);
    });
};

/**
 * Updates provider markers on the map.
 * @param {Array} providersMarkerData - Array of provider marker data.
 * @param {Set} updatedMarkerIds - Set of updated marker IDs.
 */
const updateProviderMarkers = (providersMarkerData, updatedMarkerIds) => {
    providersMarkerData.forEach(async (provider) => {
        const position = {lat: Number(provider.latitude), lng: Number(provider.longitude)};


        const icon = createMarkerIcon(provider.avatar, getProviderBadgeText(provider),true); // Pass true for providers

        const cacheKey = "p" + provider.id;
        if (!markerCache[cacheKey]) {
            markerCache[cacheKey] = await addProviderMarker(provider, position, icon);
        }
        updatedMarkerIds.add(cacheKey);
    });
};

const getProviderBadgeText = (provider) => {
    // Use the providers type_of_provider to determine the icon. It will be a text like "Health Professional". Take the
    // first letters of each word and use them as the icon text. For example, "Health Professional" would be "HP".
    return provider.type_of_provider.text.split(" ").map(word => word[0].toUpperCase()).join("");
}

const createIconElement = (avatar) => {
    const icon = document.createElement(avatar ? "img" : "div");
    if (avatar) {
        icon.src = avatar;
        icon.classList.add("rounded-full");
    } else {
        icon.innerHTML = `<svg style="width: ${ICON_SIZE - 5}px; height: ${ICON_SIZE - 5}px;" class="rounded-full overflow-hidden bg-gray-100 text-gray-300" fill="currentColor" viewBox="0 0 24 24"><path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" /></svg>`;    }
    Object.assign(icon.style, {
        width: "100%",
        height: "100%"
    });
    return icon;
}

const createBadge = (isProvider, badgeText) => {
    const badge = document.createElement("div");
    badge.textContent = badgeText
    Object.assign(badge.style, {
        position: "absolute",
        bottom: "-4px",
        right: "-4px",
        width: "20px",
        height: "20px",
        backgroundColor: isProvider ? "purple" : "maroon",
        color: "white",
        fontSize: "12px",
        fontWeight: "bold",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "50%"
    });
    return badge;
}

const createMarkerIcon = (avatar, badgeText, isProvider = false) => {
    const container = document.createElement("div");
    Object.assign(container.style, {
        position: "relative",
        width: `${ICON_SIZE}px`,
        height: `${ICON_SIZE}px`,
        border: isProvider ? "2px solid purple" : "2px solid maroon",
        borderRadius: "100%"
    });

    const icon = createIconElement(avatar);
    container.appendChild(icon);

    const badge = createBadge(isProvider, badgeText);
    if (badge) container.appendChild(badge);

    return container;
}

/**
 * Adds a member marker to the map.
 * @param {Array} members - Array of member data.
 * @param {Object} position - The position of the marker.
 * @param {HTMLElement} icon - The marker icon.
 * @returns {Object} - The created marker.
 */
const addMemberMarker = async (members, position, icon) => {
    const marker = await createMarker(position, icon, members.length > 1 ? `${members.length} people here` : `${members[0].first_name} ${members[0].last_name}`);
    addMarkerClickListener(marker, members, renderMemberDetails);
    return marker;
};

/**
 * Adds a provider marker to the map.
 * @param {Object} provider - Provider data.
 * @param {Object} position - The position of the marker.
 * @param {HTMLElement} icon - The marker icon.
 * @returns {Object} - The created marker.
 */
const addProviderMarker = async (provider, position, icon) => {

    icon = createMarkerIcon(provider.avatar, getProviderBadgeText(provider), true); // Pass true for providers
    const marker = await createMarker(position, icon, `${provider.first_name} ${provider.last_name}`);
    addMarkerClickListener(marker, [provider], renderProviderDetails);
    return marker;
};

/**
 * Adds a click listener to the marker.
 * @param {Object} marker - The marker object.
 * @param {Array} entities - Array of entities associated with the marker.
 * @param {Function} showDetails - Function to show details of the selected entity.
 */
const addMarkerClickListener = (marker, entities, showDetails) => {
    marker.addListener('click', async () => {
        const selectedEntity = entities[0];
        if (showDetails === renderMemberDetails) {
            selectionManager.setSelectedMember(selectedEntity);
            hideMemberSearch();
            showMemberDetails();
        }
        if (showDetails === renderProviderDetails) {
            selectionManager.setSelectedProvider(selectedEntity);
            hideProviderSearch();
            showProviderDetails();
        }

        const {selectedMember, selectedProvider} = selectionManager.getSelectedEntities();

        if (selectedMember && selectedProvider) {
            const bounds = new google.maps.LatLngBounds();
            bounds.extend(selectedMember.position);
            bounds.extend(selectedProvider.position);
            mapManager.getMap().fitBounds(bounds);
            enableDirectionsButton();
        }

        const infoWindowContent = entities.map(entity => `<div>${entity.first_name} ${entity.last_name}</div>`).join('');
        marker.infoWindow.setContent(infoWindowContent);
        marker.infoWindow.open(mapManager.getMap(), marker);

        showDetails(selectedEntity);

    });
};

/**
 * Aggregates markers by location.
 * @param {Array} data - Array of marker data.
 * @returns {Map} - Map of aggregated markers by location.
 */
const aggregateMarkersByLocation = (data) => {
    if (!data) return;
    const locationMap = new Map();
    data.forEach(member => {
        const key = `${member.latitude},${member.longitude}`;
        if (!locationMap.has(key)) {
            locationMap.set(key, []);
        }
        locationMap.get(key).push(member);
    });
    return locationMap;
};


export const applyFilters = async () => {
    const memberFilter = getCurrentMemberFilter().toLowerCase();
    const providerFilter = getCurrentProviderFilter().toLowerCase();

    // Assuming fetchMemberMarkers and fetchProviderMarkers are available and return all members/providers
    const allMembers = await fetchMemberMarkers();
    const allProviders = await fetchProviderMarkers();

    const filteredMembers = allMembers.filter(member =>
        member.first_name.toLowerCase().includes(memberFilter) ||
        member.last_name.toLowerCase().includes(memberFilter)
    );

    const filteredProviders = allProviders.filter(provider =>
        provider.first_name.toLowerCase().includes(providerFilter) ||
        provider.last_name.toLowerCase().includes(providerFilter)
    );

    if (!selectionManager.getSelectedMember()) renderMemberList(filteredMembers);
    if (!selectionManager.getSelectedProvider()) renderProvidersList(filteredProviders);
};

/**
 * When a member is selected, focus on the selected marker
 * @param marker Marker to focus on
 */
export const focusOnMarker = (marker) => {
    const map = mapManager.getMap();
    map.panTo(marker.position);
    map.setZoom(16);
}


export const getProviderMarker = (providerId) => {
    const providerDetails = markerCache.providers[providerId];
    if (!providerDetails) {
        console.error("Provider details not found in cache for ID:", providerId);
        return null;
    }

    const providerMarker = markerCache["p" + providerId];
    if (!providerMarker) {
        console.error("Marker not found in cache for provider ID:", "p" + providerId);
        return null;
    }

    return providerMarker;
}


export const getMemberMarker = (memberId) => {

    const memberDetails = markerCache.members[memberId];
    if (!memberDetails) {
        console.error("Member details not found in cache for ID:", memberId);
        return null;
    }

    const memberMarkerKey = `${Number(memberDetails.latitude)},${Number(memberDetails.longitude)}`;
    const memberMarker = markerCache[memberMarkerKey];
    if (!memberMarker) {
        console.error("Marker not found in cache for member ID:", memberMarkerKey);
        return null;
    }

    return memberMarker;
}