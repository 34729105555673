import { Controller } from "stimulus"
export default class extends Controller {
  static targets = [ "value", "hiddenField" ]
  
  connect() {
    this.showHiddenField()
  } 

  showHiddenField() {
    if (this.valueTarget.value >= 8) {
      this.hiddenFieldTarget.classList.remove("hidden");
      this.hiddenFieldTarget.classList.add("grid");
    } else {
      this.hiddenFieldTarget.classList.add("hidden");
      this.hiddenFieldTarget.classList.remove("grid");
    }
  }
}