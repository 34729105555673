/**
 * Initializes the Flatpickr plugin for date and time picker.
 *
 * @param {Function} changeTimeZone - The function that changes the timezone of the date.
 *
 * @return {void}
 */
export function initializeFlatpickr(changeTimeZone) {
    const flatpickrDivs = document.querySelectorAll('[id=flatpickr]')
    const timeZone = document.getElementById('time_zone')

    if (flatpickrDivs.length > 0) {

        loadFlatpickr().then((flatpickr) => {

            // flatpickr

            flatpickrDivs.forEach(flatpickDiv => {
                // default date
                let defaultDate = null
                if (flatpickDiv.dataset.defaultdate?.length > 0) defaultDate = new Date(flatpickDiv.dataset.defaultdate)
                // change the default browser timezone to the app time zone
                if (timeZone && defaultDate) defaultDate = changeTimeZone(defaultDate, `Australia/${timeZone.innerText}`)

                let datePickr = flatpickDiv.querySelector("[data-behavior='flatpickr-date']")
                let timePickr = flatpickDiv.querySelector("[data-behavior='flatpickr-time']")

                if (datePickr) {
                    // min date
                    let minDate = null
                    if (flatpickDiv.dataset.mindate) minDate = new Date().fp_incr(flatpickDiv.dataset.mindate)
                    if (flatpickDiv.dataset.mindate == 0) minDate = new Date()

                    // max date
                    let maxDate = null
                    if (flatpickDiv.dataset.maxdate) maxDate = new Date(flatpickDiv.dataset.maxdate)

                    // Settings for date picker
                    flatpickr(datePickr, {
                        altFormat: "D d F Y",
                        dateFormat: "D d F Y",
                        minDate: minDate,
                        defaultDate: defaultDate,
                        maxDate: maxDate,
                        allowInput: true, // Allows HTML5 validations to run. Investigate this when we have better FE validation strategy
                    })
                }

                // Settings for time picker
                if (timePickr) {
                    flatpickr(timePickr, {
                        enableTime: true,
                        noCalendar: true,
                        dateFormat: "h:i K",
                        defaultDate: defaultDate
                    })
                }
            })
        })
            .catch((error) => {
                console.error('Error loading flatpickr:', error);
            });


    }
}

/**
 * Loads the Flatpickr library asynchronously and returns a promise that resolves to the Flatpickr module.
 *
 * @returns {Promise<unknown>} - A promise that resolves to the Flatpickr module.
 */
async function loadFlatpickr() {
    return new Promise(async (resolve, reject) => {
        try {
            const {default: flatpickr} = await import("flatpickr");
            resolve(flatpickr)
        } catch (error) {
            reject(error.message)
        }
    })
}
